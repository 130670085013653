import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import "./App.css";

import Home from "./pages/home";
import Services from "./pages/home";
import Gallery from "./pages/home";
import Room from "./pages/home";
import Testimonials from "./pages/home/";
import FAQ from "./pages/home/";
import Contact from "./pages/home";
import Feedback from "./pages/home/";
import Sitemap from "./pages/home/";
import Privacy from "./pages/home/";
import NotFound from "./pages/home/404";
import Header from "./components/header";
import Footer from "./components/footer";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <>
      <Header></Header>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/gallery/:room" element={<Room />} />
          <Route path="/home/testimonials" element={<Testimonials />} />
          <Route path="/home/faq" element={<FAQ />} />
          <Route path="/home/feedback" element={<Feedback />} />
          <Route path="/home/sitemap" element={<Sitemap />} />
          <Route path="/home/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path='/home/feedback' element={() => { return window.location.href = "https://www.google.com"}}/> */}

          {/* <Route path='/home/feedback'                 
                loader={async () => {return redirect("https://www.google.com")}}
            /> */}

          {/* <Route 
              path="/home/feedback"
              element={() => {window.location.href = "http://maps.google.com/maps/place?cid=11890353120296775028&q=A+C+Smart+Tiling,+1+Primrose+Cottages,+Bampton,+OX18+2JA,+United+Kingdom&hl=en&ved=0CBwQ-QswAA&sa=X&ei=8NlRTYq3EofOjAf7q_m5Aw"}}
              />  */}

          {/* <Route path="/home/feedback" element={<Navigate to="https://www.example.com" replace />} /> */}
          {/* <Route path="/redirect">
            <Navigate to="https://www.example.com" replace />
          </Route> */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
      <Footer></Footer>
    </>
  );
};

export default App;
