import React from "react";
import { NavLink } from 'react-router-dom';
import './index.css';

function Header({ currentPage }) {
    function toggleMobileMenu() {
        var x = document.getElementById("menuItems");
        x.classList.toggle("in");
        
    }
    return (
        <>
            <div className="page-header" style={{ margin: "0px" }}>
                <h1 style={{ margin: "0px" }}>A C SMART<br />TILING</h1>
            </div>
            {
                process.env.NODE_ENV === 'development' && 
                <div id="devbuild">
                    This is a Dev Build
                </div>
            }
            <noscript>You need to enable JavaScript to run this app.</noscript>
        </>
      
    );
  }
    
  export default Header;




