import React from "react";

import HeaderContact from "../../components/header/contact";

import KitchenFloor from "../../assets/template/Home.jpg";

function Home() {
  const pageTitle = "Home";
  document.title = pageTitle + " - " + global.AppName;
  return (
    <>
      {/* Begin page content */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-offset-1 col-md-10">
                <h2>Welcome to A.C.Smart Tiling</h2>
                <div className="row text-center">
                  <div className="col-md-12">
                    <p>After many years of tiling Adrian is excited to announce that he has officially retired!</p>
                    <p>He is looking forward to this new chapter of relaxation, travel and time with loved ones.</p>
                    <p>We would like to thank all his customers for their business over the years.</p>
                  </div>
                </div>
                <div className="row">
                 <div className="home-list" style={{ width: "100%", textAlign: "center" }}>
                    <img src={KitchenFloor} alt="Kitchen Floor" height="300" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
