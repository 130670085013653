import React from "react";
import { NavLink } from 'react-router-dom';
import './index.css';

function Footer() {
  const currentYear = new Date().getFullYear();
    return (
        <div id="footer">
            <div className="container">
                <p className="text-muted">&copy; {currentYear}</p>
            </div>
        </div>
    );
  }
    
  export default Footer;